import { StyleParamType, wixColorParam, wixFontParam, createStylesParams } from '@wix/tpa-settings';
import { applyOpacity } from '~commons/settings/utils';

export type IStylesParams = {
  titleFont: StyleParamType.Font;
  titleColor: StyleParamType.Color;
  subtitleFont: StyleParamType.Font;
  subtitleColor: StyleParamType.Color;
  ctaTextFont: StyleParamType.Font;
  ctaTextColor: StyleParamType.Color;
  ctaBorderWidth: StyleParamType.Number;
  ctaBorderColor: StyleParamType.Color;
  ctaBorderRadius: StyleParamType.Number;
  ctaBackgroundColor: StyleParamType.Color;
  ctaHoverTextColor: StyleParamType.Color;
  ctaHoverBackgroundColor: StyleParamType.Color;
  ctaHoverBorderColor: StyleParamType.Color;
  titleFontSize: StyleParamType.Number;
  subtitleFontSize: StyleParamType.Number;
};

const styleParams = createStylesParams<IStylesParams>({
  titleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M', {
      size: 32,
    }),
  },
  titleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-text-primary'),
  },
  subtitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  subtitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-text-primary'),
  },
  ctaTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  ctaTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-action'),
  },
  ctaBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-action'),
  },
  ctaBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  ctaBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  ctaBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-fill-background-primary'),
  },
  ctaHoverTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      const headerButtonBgColor = props.getStyleParamValue(styleParams.ctaTextColor, {
        isMobile: props.isMobile,
        isRTL: props.isRTL,
      });
      return applyOpacity(
        headerButtonBgColor ??
          // Sometimes 'getStyleParamValue' returns undefined :(
          wixColorParam('color-action')(props),
        0.7,
      );
    },
  },
  ctaHoverBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      const headerButtonBgColor = props.getStyleParamValue(styleParams.ctaBackgroundColor, {
        isMobile: props.isMobile,
        isRTL: props.isRTL,
      });
      return applyOpacity(
        headerButtonBgColor ??
          // Sometimes 'getStyleParamValue' returns undefined :(
          wixColorParam('color-fill-background-primary')(props),
        0.7,
      );
    },
  },
  ctaHoverBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: (props) => {
      const headerButtonBgColor = props.getStyleParamValue(styleParams.ctaBorderColor, {
        isMobile: props.isMobile,
        isRTL: props.isRTL,
      });
      return applyOpacity(
        headerButtonBgColor ??
          // Sometimes 'getStyleParamValue' returns undefined :(
          wixColorParam('color-action')(props),
        0.7,
      );
    },
  },
  //   Mobile
  titleFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: (props) => {
      const titleFont = props.getStyleParamValue(styleParams.titleFont, {
        isMobile: props.isMobile,
        isRTL: props.isRTL,
      });
      return titleFont.size;
    },
  },
  subtitleFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: (props) => {
      const subtitleFont = props.getStyleParamValue(styleParams.subtitleFont, {
        isMobile: props.isMobile,
        isRTL: props.isRTL,
      });
      return subtitleFont.size;
    },
  },
});

export type StylesParams = typeof styleParams;

export default styleParams;
